// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.search-mobile {
  display: none;
  align-items: center;
  gap: 0.5rem;
  background-color: #363636;
  padding: 0 1rem 0 0.85rem;
  @include media("<=480px") {
    display: flex;
    margin-top: 2.5rem;
  }
  .search-icon {
    width: 20px;
    height: 20px;
  }
  input {
    background-color: transparent;
    border: none;
    color: white;
    font-family: $font-superStar;
    font-size: 18px;
    outline: none;
    @include media("<=480px") {
      width: 100%;
    }
    &::placeholder {
      color: #9a9a9a;
    }
  }
}

.leaderboard {
  width: 100vw;
  margin: 0;
  background-color: #261412;
  color: white;

  .leaderboard-page-top {
    display: flex;
    padding: 0 2.5rem;
    @include media("<=889px") {
      flex-direction: column;
      padding: 0;
    }

    .leaderboard-winner-loser-wrapper {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      button {
        font-size: 28px;
        font-family: $font-superStar;
        border: none;
        background-color: #363636;
        padding: 0rem 1rem;
        clip-path: $pixelBorderRadius;
        color: white;
        cursor: $cursor-Pointer;
        &.active {
          color: #ffa556;
        }
      }
    }

    .left-side {
      width: 70%;
      @include media("<=889px") {
        width: 100%;
      }
      .leaderboard-chart-wrapper {
        clip-path: $pixelBorderRadius;
        overflow: auto;
        overflow-x: hidden;
        height: 55rem;
        background-color: #252525;
        display: flex;
        flex-direction: column;
        position: relative;
        @include media("<=889px") {
          height: 40rem;
          clip-path: none;
          margin-top: 2rem;
        }
        .leaderboard-chart-top-buttons {
          padding: 0 1rem;
          background-color: #363636;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include media("<=480px") {
            padding: 0rem;
            justify-content: center;
          }
          .leaderboard-chart-top-left {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            div {
              cursor: $cursor-Pointer;
              font-family: $font-superStar;
              font-size: 24px;
              padding: 0.5rem 1rem;
              transition: background-color 0.3s
                cubic-bezier(0.075, 0.82, 0.165, 1);
              @include media("<=480px") {
                font-size: 20px;
              }
              @include media("<=350px") {
                font-size: 16px;
              }
              &.active {
                color: #ffa556;
                border-bottom: 3px solid #ffa556;
                background-color: #444444;
              }
            }
          }

          .leaderboard-chart-top-right {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: #434343;
            padding: 0 1rem 0 0.85rem;
            border-radius: 18px;
            @include media("<=480px") {
              display: none;
            }
            .search-icon {
              width: 20px;
              height: 20px;
              position: relative;
              left: 0.25rem;
            }
            input {
              background-color: transparent;
              border: none;
              color: white;
              font-family: $font-superStar;
              font-size: 18px;
              outline: none;
              border-radius: 10px;
              padding: 0 0.5rem;
              &::placeholder {
                color: #9a9a9a;
              }
              &:focus {
                outline: 3px solid #ffa556;
                opacity: 0.5;
              }
            }
          }
        }
        .leaderboard-top-table-headers {
          display: flex;
          justify-content: space-between;
          padding: 0.25rem 1rem;
          background-color: #363636;
          border-top: 1px solid #1b1b1a;
          border-bottom: 1px solid #1b1b1a;
          @include media("<=480px") {
            padding: 0.25rem 1rem 0.25rem 0.5rem;
          }
          .leaderboard-top-table-header-left {
            display: flex;
            gap: 4rem;
            // margin-left: 1rem;
            font-size: 24px;
            font-family: $font-superStar;
            color: #9a9a9a;
            @include media("<=480px") {
              gap: 1.5rem;
            }
          }
          .leaderboard-top-table-header-right {
            display: flex;
            gap: 12rem;
            font-size: 24px;
            font-family: $font-superStar;
            color: #9a9a9a;
          }
        }
        .leaderboard-list {
          display: flex;
          flex-direction: column;
          font-family: $font-superStar;
          font-size: 24px;
          overflow-x: hidden;
          overflow-y: auto;

          .canditate-wrapper {
            display: flex;
            padding: 0.5rem 1rem 0.5rem 0;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #1b1b1a;
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
              background-color: #353535;
            }

            .canditate-left-side {
              display: flex;
              align-items: center;
              gap: 4rem;
              .rank {
                width: 5rem;
                border-radius: 0 4px 4px 0;
                font-size: 36px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 0.6rem 0 0;
                gap: 0.25rem;
                font-weight: 1000;
                &.one {
                  .rank-number {
                    margin-left: 0.55rem;
                  }
                  background-color: #cbb765;
                  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
                    0px 0px 5.3px rgba(0, 0, 0, 0.028),
                    0px 0px 10px rgba(0, 0, 0, 0.035),
                    0px 0px 17.9px rgba(0, 0, 0, 0.042),
                    0px 0px 33.4px rgba(0, 0, 0, 0.05),
                    0px 0px 80px rgba(0, 0, 0, 0.07);
                }
                &.two {
                  background-color: #8e91a7;
                  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
                    0px 0px 5.3px rgba(0, 0, 0, 0.028),
                    0px 0px 10px rgba(0, 0, 0, 0.035),
                    0px 0px 17.9px rgba(0, 0, 0, 0.042),
                    0px 0px 33.4px rgba(0, 0, 0, 0.05),
                    0px 0px 80px rgba(0, 0, 0, 0.07);
                }
                &.three {
                  background-color: #a86243;
                  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
                    0px 0px 5.3px rgba(0, 0, 0, 0.028),
                    0px 0px 10px rgba(0, 0, 0, 0.035),
                    0px 0px 17.9px rgba(0, 0, 0, 0.042),
                    0px 0px 33.4px rgba(0, 0, 0, 0.05),
                    0px 0px 80px rgba(0, 0, 0, 0.07);
                }
                img {
                  width: 25px;
                  height: 25px;
                }
              }
              .wallet-address {
                font-size: 18px;
              }
            }
            .canditate-right-side {
              display: flex;
              justify-content: space-between;
              width: 35rem;
              font-size: 36px;
              .start-amount {
                position: relative;
              }
              .end-amount {
                position: relative;
                left: -2rem;
              }
            }
          }
        }

        .pagination-element-wrapper {
          display: flex;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 2rem;
          gap: 0.25rem;
          background-color: #363636;
          padding: 0.25rem 0.5rem;
          clip-path: $pixelBorderRadius;
          max-width: 40rem;
          overflow-x: auto;
          scroll-behavior: smooth;
          @include media("<=768px") {
            max-width: 100%;
          }
          .pagination-scroll-btn {
            position: sticky;
            display: none;
            z-index: 10;
            cursor: $cursor-Pointer;
            background-color: transparent;
            outline: none;
            border: none;
            font-size: 32px;
            color: #ffa556;
            user-select: none;

            &.pagination-left {
              left: 0;
            }
            &.pagination-right {
              right: 0;
            }
          }

          &.scroll-active {
            padding: 0.25 10rem;
            .pagination-scroll-btn {
              display: block;
            }
          }

          .pagination-element {
            user-select: none;
            flex: 0 0 auto;
            clip-path: $pixelBorderRadius;
            font-size: 24px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: $cursor-Pointer;
            background-color: #151515;
            color: #ffa556;
            outline: none;
            border: none;
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &.active {
              background-color: #ffa556;
              color: #151515;
              transform: scale(1.1);
            }
            &:hover,
            &:focus {
              background-color: #ffa556;
              color: #151515;
            }
          }
        }
      }
    }

    // ! Right side
    .right-side {
      width: 30%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex;
      font-family: $font-superStar;
      gap: 0.5rem;
      position: relative;
      top: -5.4rem;
      padding-left: 3rem;
      @include media("<=889px") {
        width: 100%;
        margin-top: 7.5rem;
        height: auto;
        justify-content: center;
      }
      @include media("<=480px") {
        padding: 1rem;
      }
      .region-filters-box {
        clip-path: $pixelBorderRadius;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: space-between;
        font-size: 28px;
        height: 10rem;
        width: 25rem;
        padding: 1rem;
        box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
        @include media(">=1920px") {
          width: 30rem;
        }
        @include media("<=1450px") {
          width: 20rem;
        }
        @include media("<=889px") {
          width: 100%;
          height: auto;
          text-align: center;
          padding: 1rem 0;
          clip-path: none;
          background-color: transparent;
          box-shadow: none;
        }
      }
      .logintojoin-leaderboard {
        border-radius: 3.5px;
        width: 100%;
        font-size: 20px;
        color: black;
        display: flex;
        justify-content: center;
        line-height: normal;
        padding: 0.5rem 1rem;
        color: #1b1b1a;
        background-color: #ffa556;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: $pixelBorderRadius;
        div {
          p {
            font-size: 28px;
          }
        }
        @include media("<=1450px") {
          font-size: 16px;
        }
        @include media("<=889px") {
          text-align: center;
          border-radius: 0;
        }
      }
      .leaderboard-warning {
        font-family: $font-pixellari;
        max-width: 30rem;
        line-height: normal;
        font-size: 24px;
        color: #ffa556;
        position: relative;
        text-align: center;
        @include media("<=889px") {
          font-weight: 100;
          padding: 0 1rem;
          text-align: center;
        }
      }
    }
  }
}

// ! No wallet page
.nowallet {
  width: 100%;
  height: 50vh;
  position: relative;
  @include media("<=480px") {
    height: auto;
  }
  .connectyourwallet-wrapper {
    display: flex;
    justify-content: center;

    .connectyourwallet-box {
      display: flex;
      @include media("<=480px") {
        flex-direction: column;
        align-items: center;
      }
      .connectyourwallet-left {
        .sagebear-gif {
          width: 300px;
          @include media("<=480px") {
            width: 250px;
            position: relative;
            left: -5rem;
          }
        }
      }
      .connectyourwallet-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10rem;
        @include media("<=480px") {
          margin-bottom: 0;
        }
        .connectyourwallet-right-top {
          .sagebear-bubble {
            position: relative;
            top: -5.5rem;
            left: -13rem;
            width: 400px;
            z-index: 1;
            user-select: none;
            pointer-events: none;
            @include media("<=480px") {
              position: absolute;
              top: 10%;
              left: 65%;
              transform: translate(-50%, -50%);
              width: 250px;
            }
          }
        }
        .connectyourwallet-right-bottom {
          @include media("<=480px") {
            margin-top: 2rem;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.round-time-wrapper {
  color: white;
  font-family: $font-superStar;
  font-size: 24px;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 25rem;
  background-color: #252525;
  word-spacing: -1px;
}
