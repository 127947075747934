.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 150px;
  }
}
