// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.modal-back {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  top: 0px;
  left: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-header {
  font-size: 42px;
  display: flex;
  text-align: center;
  font-family: $font-superStar;
  color: rgb(156, 156, 156);
}
.modal-title {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.modal-custom {
  background-color: #222;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 1rem;
  width: 50rem;
  border-radius: 30px;
  border: 15px solid transparent;
  border-image: url("../../assets/media/icons/LoginBorder.png") 30 stretch;
  z-index: 10000;
  @include media("<=1350px") {
    padding: 0.5rem;
  }
  @include media("<=889px") {
    width: 90%;
  }
  @include media("<=480px") {
    width: 100%;
  }
}
.modal-custom-extend {
  background-color: #160538;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  padding: 32px;
  border-radius: 12px;
  width: 50%;
}

.term-div {
  width: 100%;
  height: 100%;
  color: white;
  font-family: $font-superStar;
  font-size: 20px;
  overflow: hidden;
  .signup-walletadress {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    line-height: normal;
    font-size: 18px;
    .warning-text {
      font-family: $font-Pixellari;
      font-size: 20px;
      color: #ddd;
    }
    .modal-wallet-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .wallet-text {
        font-family: $font-Pixellari;
        font-size: 32px;
        position: relative;
        top: 0.05rem;
        color: #ddd;
      }
      .wallet-adress {
        font-size: 36px;
        color: #ffa556;
      }
    }
  }
  .signup-buttons-wrapper {
    display: flex;
    @include media("<=480px") {
      gap: 1rem;
      font-size: 16px;
      line-height: normal;
    }
  }
}
.term-div::-webkit-scrollbar {
  width: 12px;
}
.term-div::-webkit-scrollbar-track {
  background: #160538;
}
.term-div::-webkit-scrollbar-thumb {
  background-color: rgba(115, 85, 180, 0.4);
  border-radius: 20px;
  border: 2px solid #160538;
}

.modal-close {
  position: relative;
  top: 0.25rem;
  cursor: $cursor-Pointer;
  font-size: 42px;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: $pixelBorderRadius;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  svg {
    path {
      fill: rgb(156, 156, 156);
    }
  }
}

// Buttons
.signup-buttons-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  @include media("<=480px") {
    gap: 0;
  }
  .login-discord {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    font-family: $font-superStar;
    font-size: 22px;
    cursor: $cursor-Pointer;
    color: #f27921;
    clip-path: $pixelBorderRadius;
    padding: 0.4rem 1rem;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: #333333;
    &:hover {
      background-color: #151515;
    }
  }

  .login-twitter {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    font-family: $font-superStar;
    font-size: 22px;
    cursor: $cursor-Pointer;
    color: #f27921;
    clip-path: $pixelBorderRadius;
    padding: 0.4rem 1rem;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: #333;
    &:hover {
      background-color: #151515;
    }
  }
}
