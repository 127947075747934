// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;

.user-notification-popup-wrapper {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 8888;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .user-notification-popup {
    width: 0;
    height: 15rem;
    background-color: #202020;
    color: white;
    display: flex;
    flex-direction: column;
    opacity: 0;
    border-radius: 20px;
    border: 12.5px solid transparent;
    border-image: url("../../assets/media/icons/LoginBorder.png") 25 stretch;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include media("<=768px") {
      max-width: 100%;
      height: 16rem;
    }
    .congrats-message {
      font-size: 28px;
      text-align: center;
      line-height: normal;
      opacity: 0;
      @include media("<=768px") {
        font-size: 24px;
      }

      span {
        color: #00ff41;
      }
      img {
        width: 50px;
        display: inline-block;
        position: relative;
        top: 1rem;
        @include media("<=768px") {
          width: 40px;
          top: 0;
        }
      }
    }

    .copy-btn {
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      border-radius: 10px;
      background-color: #101010;
      border: none;
      padding: 0.25rem 1rem;
      cursor: $cursor-Pointer;
      color: white;
      font-size: 18px;
    }
    .notification-popup-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -1rem;
      opacity: 0;
      @include media("<=768px") {
        flex-direction: column;
        top: 0;
      }

      .notification-share-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        border-radius: 10px;
        background-color: #101010 !important;
        padding: 0.5rem 1rem !important;
        cursor: $cursor-Pointer !important;
        img {
          width: 30px;
          display: inline-block;
        }
        span {
          font-size: 20px;
        }
        &:hover {
          background-color: #424242 !important;
        }
      }

      .okay-button {
        font-size: 24px;
        font-family: $font-superStar;
        text-decoration: underline;
        text-underline-offset: 5px;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        color: #dddddda2;
        cursor: $cursor-Pointer;
        &:hover {
          color: #ddd;
        }
      }
    }
  }

  .image-paste-info {
    opacity: 0;
    color: white;
    font-size: 32px;
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    @include media("<=768px") {
      font-size: 16px;
      bottom: 1.5rem;
    }
  }
}
